import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "react-chat-67d8d.firebaseapp.com",
  projectId: "react-chat-67d8d",
  storageBucket: "react-chat-67d8d.appspot.com",
  messagingSenderId: "1064079430810",
  appId: "1:1064079430810:web:ac7e9e044415b24dfc7b23"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage();
export const db = getFirestore()
